$(function() {

    //Prevent input of e,+,- characters on number type inputs
    //Chance in future we may need to remove the "-" from this if we need negative numbers anywhere
    $(':input[type="number"]').keydown(function () {
  			if(event.keyCode == 69 || event.keyCode == 107 || event.keyCode == 109 || event.keyCode == 187 || event.keyCode == 189 ){
  				return false;
  			}else{
  				return true;
  			}
  	});


  	// Responsive Tables

  	if ($('table.ui.table').length) {
	    $('table.ui.table').each(function() {
    		var $table = $(this);
        	$rows = $table.children('tbody').children();
    		$table.children('thead').children().children().each(function(i) {
       	 		var text = $(this).text();
        		$rows.each(function() {
            		$(this).children().eq(i).attr('data-content', text);
        		});
    		});
		});
	}

    // Hide & Show toggles

    $('.show-switch').on('change', function(){
      $(this).parent().parent().find('.toggle-show').fadeToggle('fast');

      if ( $(this).checkbox('is unchecked') ) {
      $(this).parent().parent().find('.toggle-show').hide();
      $(this).closest('.ui.segment.show-switch-container').addClass('secondary');
      }

      else if ( $(this).checkbox('is checked') ) {
        $(this).parent().parent().find('.toggle-show').show();
        $(this).closest('.ui.segment.show-switch-container').removeClass('secondary');
      }

      // Subs edit page highlights container segment when toggled
      $(this).closest('.ui.segment.show-switch-container').toggleClass('secondary');
    });

    $('.show-switch').each(function(){
      if ($(this).checkbox('is checked')) {
        $(this).parent().parent().find('.toggle-show').show();
      	$(this).closest('.ui.segment.show-switch-container').addClass('secondary');
      }
    });

    // Hide messages with close

    $('.message .close').on('click', function() {
      $(this).closest('.message').fadeOut();
    });

    if($('.public-messages').length) {
      $('.public-messages').prependTo('main.public-container');
      $('main.public-container').css('padding-top', '1rem');
    }

    $('.message-close').on('click', function(){
      $(this).closest('.public-messages').fadeOut('fast');
      $('main.public-header').css('padding-top', '5rem');
    });

    //my clubs dropdown redirect on select
    $('#my_clubs').change(function(e){
        var optionSelected = $("option:selected", this);
        window.location.href = optionSelected.data('url');
    });

    $('#subscription_list').change(function(){
        subscriber = $(this).val();
        //check this has a value
        if(subscriber){

          subscriber_title = $('#subscription_list option:selected').text();
          $('#subscription_list option:selected').attr('disabled','disabled');
          //chosen subscriber values and remove button
          $('#subscriptions_selected').append('<div id="sub-'+subscriber+'">'+subscriber_title+'<a href="#"  class="remove_subscriber" data-id="'+subscriber+'" style="background-color:#ff9999;">X</a><input name="subscriber_types[]" type="hidden" value="'+subscriber+'"></div>');

          //set remove function
          $(".remove_subscriber").click(function(){
              console.log('Click Function Triggered');
              $("#sub-"+$(this).data('id')).remove();
              $('#subscription_list option[value='+$(this).data('id')+']').removeAttr('disabled');
          });

          $(this).find('option').first().prop('selected', true);//return select to first option

        }

    });

    //Generic Ajax submit function
    //Could do with a little tiding up
    //add class ajax_submit to form, and classes
    //ajax_reponse_success and ajax_reponse_error where ypu want reponse messages displayed
    $('.ajax_submit').on('submit', function(e) {
        var theForm = $(this);
        //disable all submit buttons and show loading icon
        theForm.find('[type="submit"]').addClass('loading').prop( "disabled", true );
        //make sure any currently showing messages are hidden again
        theForm.find('.ajax_reponse_success, .ajax_reponse_error').hide();
        e.preventDefault();
        $.ajax({
            url: $(this).attr('action'),
            dataType: 'json',
            method: 'POST',
            data: '_token=' + csrftoken + '&' + $(this).serialize(),
            success: function(content) {
                theForm.find('.ajax_reponse_success').show();
                theForm.find('.ajax_reponse_success > span').html(content.msg);
                //remove loading icon and re-enable button
                theForm.find('[type="submit"]').removeClass('loading');
                theForm.find('[type="submit"]').removeClass('loading').prop( "disabled", false );
            },
            error: function(content) {
                theForm.find('.ajax_reponse_error').show();
                theForm.find('.ajax_reponse_error > span').html(content.msg);
                //remove loaidng icon and reenable button
                theForm.find('[type="submit"]').removeClass('loading').prop( "disabled", false );
            }
        });

    });

    $('.show-optional').change(function() {
        if ($(this).is(':checked')) {
            $(this).closest('div.field').next('.optional-field:hidden').fadeIn();
        }
        else {
           $(this).closest('div.field').next('.optional-field:visible').fadeOut();
           $(this).closest('div.field').find('.optional-field input[type="checkbox"]').prop('checked', false);
           $(this).closest('div.field').find('.optional-field input[type="text"], .optional-field input[type="number"]').val('');
        }
    });


    $('.optional-affiliated .show-optional').change(function() {
        if ($(this).is(':checked')) {
            $(this).closest('div.field').next('.optional-field:hidden').fadeIn();
            $(this).closest('div.field').find('.optional-field input[type="checkbox"]').prop('checked', true);
        }
        else {
           $(this).closest('div.field').next('.optional-field:visible').fadeOut();
           $(this).closest('div.field').find('.optional-field input[type="checkbox"]').prop('checked', false);
        }
    });

    //Members details expand
    $('.member_details_toggle').click(function(e){

        var member_id  = $(this).data('member');
        var member_url = $(this).data('url');
        var details_loaded = $('#member_'+member_id).data('loaded');

        $(this).toggleClass('active');

        $(this).find('i.dropdown.icon').toggleClass('rotated');

        if($(this).hasClass('tablesorter-hasChildRow')) {
        	$(this).next('tr.tablesorter-childRow').find('td').toggleClass('hideme');
        }

        $(this).closest('td.member_details.expand-child').toggleClass('hidden');

        $('#member_'+member_id).toggle();
        if(!details_loaded){
          $.get(member_url, function(data){
            $('#member_'+member_id).html(data);
            $('#member_'+member_id).data('loaded', 1);
            $('table.member-details').each(function(){
	          	   var th_data;
  	          	$('table.member-details thead.first-thead').each(function() {
  	              $(this).find('th').each(function(index){
  	              var index_th = index;
  	              var th = $(this);
  	              th_data = $(th).text();
  	              $('table.member-details tbody.first-tbody').each(function() {
  	                $(this).find('td').each(function(index){
  	                  var index_td = index;
  	                  if (index_th == index_td) {
  	                      var td = $(this);
  	                      $(td).attr('data-content', th_data);
  	                  }
  	                });
  	              });
  	            });
  	          });
	            var th_data_two;
  	          $('table.member-details thead.second-thead').each(function() {
  	              $(this).find('th').each(function(index){
  	              var index_th = index;
  	              var th = $(this);
  	              th_data_two = $(th).text();
  	              $('table.member-details tbody.second-tbody').each(function() {
  	                $(this).find('td').each(function(index){
  	                  var index_td = index;
  	                  if (index_th == index_td) {
  	                      var td = $(this);
  	                      $(td).attr('data-content', th_data_two);
  	                  }
  	                });
  	              });
  	            });
  	          });
  	        });
          });
        }
    });

    //Ajax call to Club Search
    $('.search_form input').on('input keypress', function(e) {
        q = $(this).val();
        var parent = $(this).closest(".search_form")
        var uri = parent.data("action");
        var uri = uri+q;
        var show = parseInt(parent.data("show"));
        if(show === parseInt(parent.data("show"), 10) || !isNaN(show)){ }
        else {
            show = 3;
        }
        if (q.length > 2) {
            $.getJSON( uri, function( data ) {
                var items = [];
                $.each( data, function( key, val ) {
                    var main_div = '<tr data-id="' + val.id + '">';
                    var addi = '<tr data-id="'+ val.id +'" class="rnJq-hidden" style="display:none;">';
                    count = 1;

                    for (column_heading in val){

                        var value_to_use = val[column_heading];
                        if (column_heading != "id" && count > show){
                            addi += "<td name='"+column_heading+"'>"+value_to_use+"</td>";
                        } else if(column_heading != "id" && count <= show){
                            main_div += "<td name='"+column_heading+"'>"+value_to_use+"</td>";
                            count++;
                        }
                    }
                    addi+="</tr>";
                    main_div+="</tr>";
                    items.push( main_div );
                    items.push( addi );
                    items.push("<tr></tr>");
                });
                $('.search_form input').on('focus click', function(){
                    $(".rnJq-c-search-results, .rnJq-pick-results").show();
                });
                var res = parent.children(".rnJq-pick-results").show();
                if(res.length < 1){
                    var res = parent.children(".rnJq-c-search-results").show();
                }
                res.empty();
                $( '<table />', {
                    'class': 'ui simple striped table autocomplete-results',
                    html: items.join()
                }).appendTo( res );
            });
        }

         else if (q == "") {
            $('.search_form input').on('focus', function(){
                $(".rnJq-c-search-results, .rnJq-pick-results").hide();
            });
            $(".rnJq-c-search-results, .rnJq-pick-results").hide();
        }
        else {
            var res = parent.children(".rnJq-pick-results");
            if(res.length < 1){
                var res = parent.children(".rnJq-c-search-results");
            }
            res.html('');
            $('[name=join_group]').hide();
            $(".rnJq-c-search-results, .rnJq-pick-results").hide();
        }
    });

    var $resWidth = $('.search_form').width();

    $(".rnJq-c-search-results, .rnJq-pick-results").css('width', $resWidth);

    $('body:not(.search_form input), body:not(.rnJq-c-search-results), body:not(.rnJq-pick-results)').on('mouseup', function(e){
        $(".rnJq-c-search-results, .rnJq-pick-results").hide();
    });

    // Clicking on a row in the search results //
    $('.search_form').on('click', 'tr', function(ev) {
        ev.preventDefault();

                if ($(this).data('id') && $(this).parents('.search_form').data('link')) {
                        window.location.href = $(this).parents('.search_form').data('link') + $(this).data('id');
                }
    });

    // Date picker (uses /public/js/bower_components/semantic-ui-calendar)

    $('.datetime-pick').calendar({
        type: 'datetime',
        ampm: false,
        formatter: {
            datetime: function (date, settings) {
                if (!date) return '';
                var hours = (date.getHours() < 10 ? "0"+date.getHours() : date.getHours());
                var mins = (date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes());
                return $.format.date(date, "D MMM yyyy")+', '+hours+':'+mins;
            }
        },
    });

    $('.date-pick').calendar({
        type: 'date',
        formatter: {
            date: function (strDate, settings) {
                if (strDate != undefined) {
                   return $.format.date(strDate, "D MMM yyyy");
                }
            }
        }
    });

    $('.date-pick, .dob, .date:not(.ui.feed .date)').calendar({
        type: 'date',
        formatter: {
            date: function (strDate, settings) {
                if (strDate != undefined) {
                   return $.format.date(strDate, "D MMM yyyy");
                }
            }
        }
    });

    if($('.dob-fields').length) {

        // Date of birth select boxes

        $.dobPicker({
            // Selectopr Classes
            daySelector: '.dob-day',
            monthSelector: '.dob-month',
            yearSelector: '.dob-year',

            // Default option values
            dayDefault: 'Day',
            monthDefault: 'Month',
            yearDefault: 'Year',

            // Minimum age
            minimumAge: 1,

            // Maximum age
            maximumAge: 130
        });

    }

     // Date of birth picker with select fields

  $('.dob-fields select').on('change', function(){

      var day = $('.dob-day').val();
      var month = $('.dob-month option:selected').text().substring(0,3);
      var year = $('.dob-year').val();

      /*function nth(day) {
          if(day>3 && day<21) return 'th';
            switch (day % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
          }
      } */

      var dob = ""+day+" "+month+" "+year+"";

      $(this).closest('input.dob-input').val(dob);

  });

    $(document).on('click', '.edit:not(i.edit.icon)', function(ev) {
        ev.preventDefault();

        var $btn = $(this);
        var $content = $(this).prev('div.content');

        $.get($btn.attr('href'), function(data) {
            $content.html(data);
            $btn.hide();
        });
    });


    $(document).on('click', '.ajax_submit', function(ev) {
        ev.preventDefault();

        var $form = $(this).closest('form');
        var $content = $(this).closest('.content');
        var $btn = $content.next('a.edit');

        $.ajax({
            url: $form.attr('action'),
            dataType: 'html',
            method: 'PUT',
            data: '_token=' + csrftoken + '&' + $form.serialize(),
            success: function(content) {
                $content.html(content);
                $btn.show();
            },
            error: function() {
                alert("Sorry, that didn't save properly, please try again.");
            }
        });
    });


    // Postcode button on pub sub form

    $('body.public .address_form button').on('click', function(){
    	$('.address-field').hide();
    });


    // Mobile Menu button etc

    $('.ui.sidebar.mobile-sidebar').sidebar({
    	onShow: function(){
    		$('.ui.sidebar.mobile-sidebar').addClass('is_shown');
    		$('#mobile-menu-btn').unbind( "click" );
    	},
        onVisible: function(){
            $('#mobile-sidebar,  #mobile-sidebar .club-admin-menu').show();
        },
        onHide: function(){
        	$('.ui.sidebar.mobile-sidebar').removeClass('is_shown');
        },
        onHidden: function() {
        	$('#mobile-sidebar,  #mobile-sidebar .club-admin-menu').hide();
        	$('#mobile-menu-btn').on('click', function() {
    			$('.ui.sidebar.mobile-sidebar').sidebar('setting', 'transition', 'overlay').sidebar('setting', {dimPage: false}).sidebar('show');
    		});
        }
    });

    $('#mobile-menu-btn').on('click', function() {
    	$('.ui.sidebar.mobile-sidebar').sidebar('setting', 'transition', 'overlay').sidebar('setting', {dimPage: false}).sidebar('toggle');
    });

////////////////////////////////////////////////////////////////////////

    $(document).on('click', '.confirm-choice', function(ev) {
        ev.preventDefault();
        $btn = $(this);

        $('.ui.small.modal .content > p').html($(this).data('question'));
        $('.ui.small.modal.confirm').modal({
            blurring: true,
            closable  : false,
            onDeny    : function() {
                return true;
            },
            onApprove : function() {
                if ($btn.attr('href')) {
                    window.location.href = $btn.attr('href')
                }
                else {
                    $btn.closest('form').submit();
                }
            }
        }).modal('show');
    });


    $(document).on('click', '.confirm-alert', function(ev) {
        ev.preventDefault();
        $btn = $(this);

        $('.ui.small.modal .content > p').html($(this).data('question'));
        $('.ui.small.modal').modal({
            blurring: true,
            closable  : false,
            onDeny    : function() {
                return true;
            },
            onApprove : function() {
                if ($btn.attr('href')) {
                    window.location.href = $btn.attr('href')
                }
                else {
                    $btn.closest('form').submit();
                }
            }
        }).modal('show');
    });



    $('.helper-action').on('click', function(){

        if( !$(this).parent().is('td')) {
          var helpTitle = $(this).closest('.field').find('label').text();
        }
        else {
           var helpTitle = $(this).parent().text();
        }

        var helpText = $(this).attr('data-helper-text');


        $('<div class="item"><div class="header"><h3 class="ui header">'+helpTitle+'</h3></div><div class="half-spacer"></div>'+helpText+'</div>').prependTo('.ui.sidebar.tips .list');

        $('.ui.sidebar.tips').sidebar('setting', 'transition', 'overlay').sidebar('setting', 'dimPage', 'false').sidebar('toggle');

    });

    // Clear the sidebar of its content when closed

    $('.ui.sidebar.tips').sidebar({
        onHidden: function(){
            $('.ui.sidebar.tips .list').html('');
        }
    });

    $('#close-tips').click(function(){
        $('.ui.sidebar.tips').sidebar('hide');
    });

   // Innit drop drop down select boxes
   $('.ui.dropdown').dropdown();

   $('.ui.checkbox').checkbox();

   $('table.sortable').tablesort();

   $('.ui.acordion').accordion();

   $('.info-popup').popup();

   $('.my-rn-home .menu .item').tab();

   $('button.fi-upload').addClass('ui icon button');
   if ( $('button').text() == 'Upload' ) {
       $(this).prepend('<i class="upload icon"></i>');
   }


   // Custom Accordion for displaying various events / tickets (compatible with IE8 and above)

   $('.public-accordion .content').each(function(){

    if ($(this).hasClass('active') ) {
        $(this).prev().addClass('active');
        $(this).prev().find('i').addClass('rotated');
    }

  });


    $('.public-accordion .title').click(function() {

        var $this = $(this);

        $this.addClass('active');
        $(this).find('.dropdown.icon').addClass('rotated');

        if ( $this.next().hasClass('active') ) {
            $this.next().removeClass('active');
            $this.next().slideUp(350);
            $this.removeClass('active');
            $this.find('.dropdown.icon').removeClass('rotated');
        }

        else {
            $this.parent().find('div .content').removeClass('active');
            $this.next().toggleClass('active');
            $this.next().slideToggle(350);
            $this.addClass('active');
            $this.find('.dropdown.icon').addClass('rotated');
        }



    });


  function prepareUpload(event)
  {
    files = event.target.files;
     var form = this.closest('form');
     // Remove prop (globally)
     $('button', form).prop('disabled', false);
     // Check if the file is larger than 15mb.
     if (files[0].size > (1048576*15)) {
       // Make sure the upload button is disabled.
       $('button', form).prop('disabled', 'disabled');
       // Style for error messages div.
       $('.form-messages', form)
         .css({'flex' : '0 0 21%',  'margin-left' : '10px'})
         .html('<div class="ui red center aligned label"><i class="warning sign icon"></i>File size is too large</div>')
         .fadeIn();
     }
  }

  // Catch the form submit and upload the files
  function uploadFiles(event)
  {
      event.stopPropagation(); // Stop stuff happening
      event.preventDefault(); // Totally stop stuff happening

      // Create a formdata object and add the files
      var data = new FormData();
      $.each(files, function(key, value)
      {
          data.append(key, value);
      });

      data.append('type', $(this).attr('data-type'));
      data.append('folder', $(this).attr('folder'));
      data.append('displayedOn', $(this).attr('data-displayedon'));
      data.append("_token", csrftoken);
      $action = $('.uploadForm').attr('action');

      $.ajax({
          url: $action,
          type: 'POST',
          data: data,
          cache: false,
          dataType: 'json',
          processData: false, // Don't process the files
          contentType: false, // Set content type to false as jQuery will tell the server its a query string request
          success: $.proxy(function(data, textStatus, jqXHR)
          {
              // Success so call function to process the form
              // submitForm(event, data);
              var imgbox = $(this).find("[name='image-output']");
              var url = data.img;
              var img_id = data.img_id;
              url = url.replace(/[\\]/g, "");
              imgbox.html(" <img src="+url+" class='ui bordered rounded image' /><!--<i style='padding:0px; color:#368a55; position: absolute;top: 15px;left: 96.5%;' class='checkmark icon'></i>-->");
              $('button.fi-upload').removeClass('loading');
              $(this).find('.upload-elems').hide();
              $(this).find('.form-messages').html('<div class="ui green center aligned label"><i class="checkmark icon"></i> Image upload complete</div>').fadeIn();
              //get delete button for uploaded image working
              var remove_image_el = $(this).siblings('.remove-image');
              remove_image_el.find('.remove-image-form').attr('action', function(i, v){
                return v.replace(/xxx/, img_id);
              });
              remove_image_el.show().fadeIn();

              setTimeout(function() {imgbox.removeClass("flashHiddenImg");}, 0.5);

          }, this),
          error: $.proxy(function(data, textStatus, jqXHR)
          {
              // Handle errors here
              var imgbox = $(this[1]).children("[name='image-output']");
              var json = JSON.parse(data.responseText);
              imgbox.html("<p style='text-align: center;'><i style='color:#f04124;' class='fi-x'></i></br>"+json.code+" error </br>"+json.error+"</p>");
              setTimeout(function() {imgbox.removeClass("flashHiddenImg");}, 0.5);
              $(this).find('.upload-elems').hide();
              $('input:file').val('');
              $(this).find('.form-messages').html('<div class="ui red center aligned label"><i class="warning sign icon"></i> '+json.error+'</div>').fadeIn();
              console.log(data.responseText);
          }, this)
      });
  }

  // Variable to store your files
  var files;
  // Add events
  $('input[type=file]').on('change', prepareUpload);
  $('.uploadForm').on('submit', uploadFiles);

  // Make file upload fields look a bit nicer: <input type="file"> is hidden but triggred when 'fake' text input is clicked on

  // When user clicks on browse button
  $('.browse-file').each(function(){

      var filename = $(this).parent().find('input:file').val();

      $(this).on('click', function(e) {
      $(this).parent().find('input:file').click();
      $(this).parent().find('.form-messages').fadeOut();
      });
  });

  // When user selects a file (when a different file to the one selected is chosen) show the text field contain file name, make upload button appear
  $('input:file').each(function(){
      $(this).on('change', function(e) {
          var name = e.target.files[0].name;
          $(this).parent().find($('input:text', $(e.target).parent())).val(name).transition('slide right in').show();
          $(this).parent().find($('.file-name', $(e.target).parent())).val(name);
          $(this).parent().find('.fi-upload').transition('slide down in').show();
          $(this).parents('.upload-elems').show().find('.fi-upload').attr('class', 'ui navy right labeled icon small button fi-upload');
      });

  });

  // Make loading animation appaear on upload button when pressed
  $('button.fi-upload').on('click', function(){
      $(this).attr('class', 'ui navy right labeled icon small loading button fi-upload');
  });

  $(".activate_subscription").on('change', function(){

      if($(this).is(":checked")){
        toggle_value = 1;
      }else{
        toggle_value = 0;
      }

      theCheckbox = $(this);

      $.ajax({
          url: $(this).data('url'),
          type: 'GET',
          data: 'toggle='+toggle_value,
          dataType: 'json',
          error: function(data){
            if(data.error == 'members'){
              alert('Cannot change subscription state as it has active memberships.');
            }else{
              alert('There was an unknown error, de/activating the subscription.');
            }
          },
          success: function(data){
            if(data.error == 'members'){
              //theCheckbox.attr('checked', true);
              alert('Cannot change subscription state as it has active memberships.');
              alert('TODO: Reset checkbox to original state');
            }
          }
      });

  });

  $("#preview_mail").click(function(){
    $('#email_form').attr('target', '_blank');
  });

  $(".copy_url").click(function(){
      //assign data-url value to hidden text field
      //so it can be selected and copied to clipboard
      $("#copy_text").val($(this).data('url'));
      $("#copy_text").select();
      document.execCommand("copy");
      $("#copy_text").blur();
      if($(window).width() >= 768) {
      	$('.copy_success').fadeIn().delay(5000).fadeOut();
  	  }
  	  else {
  	  	$('.copy_success').find('.right.aligned.column').removeClass('right aligned').addClass('left aligned');
  	  	$('.copy_success').css('padding-left', '0.8rem').prependTo($(this).closest('tr')).fadeIn().delay(5000).fadeOut();
  	  }
  });

});


// Alert & warning banenrs

//$('.ui.negative.message.error-banner, .ui.positive.message.alert-info').insertAfter('h1.ui.header').css({'width': '100%', 'margin-bottom': '2rem'}).fadeIn();


/*$.notifyBar({
    jqObject: $('.ui.negative.message.error-banner'),
    //cssClass: "negative",
    close: false,
    closeOnClick: true,
    onShow: function(){
        $('header.sponsorship-header').css('position', 'relative');
        $('.public .public-container').css({'tansition':'all linear 0.1s', 'padding-top': '0'});
    },
    onHide: function(){
        $('header.sponsorship-header').css('position', 'fixed');
        alert('hidden!');
        $('.public .public-container').css({'tansition':'all linear 0.1s', 'padding-top': '0'});
    },
    delay: 8000
});

$.notifyBar({
    jqObject: $('.ui.message.alert-info'),
    cssClass: "positive",
    close: true,
    closeOnClick: true,
    delay: 8000
}); */

$('.ui.message .close.icon')
  .on('click', function() {
    $(this)
      .closest('.message')
      .transition('fade')
    ;
  })
;

// Read more button on entry pages

$('.retract-event-details').hide();

var event_desc = $('.event-desc');
var event_desc_height = event_desc.height();

event_desc.css({'height': '118px', 'overflow': 'hidden'})

$('.expand-event-details').click(function(){
    $(this).hide();
    event_desc.animate({'height': event_desc_height}, 250);
    $('.retract-event-details').show();

});

$('.retract-event-details').click(function(){
    $(this).hide();
    event_desc.animate({'height': '118px'}, 250);
    event_desc.css({'overflow': 'hidden'})
    $('.expand-event-details').show();
});

var title = $('h1.content, h1.sponsor-me').contents().not($(".sub.header")).text();

if (title) {

    $('title').text('MyRaceNation - '+title);

}


// Check header / footer background colour lightness to decide whether the text is light or dark


$.fn.contrastColour = function() {

    return this.each(function() {

    var bg = $(this).css('background-color');

    //use first opaque parent bg if element is transparent
    if(bg == 'transparent' || bg == 'rgba(0, 0, 0, 0)') {
        $(this).parents().each(function(){
        bg = $(this).css('background-color')

        if(bg != 'transparent' && bg != 'rgba(0, 0, 0, 0)') return false;
        });

        //exit if all parents are transparent
        if(bg == 'transparent' || bg == 'rgba(0, 0, 0, 0)') return false;
        }

        //get r,g,b and decide
        var rgb = bg.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
        var yiq = ((rgb[0]*299)+(rgb[1]*587)+(rgb[2]*114))/1000;

        if (rgb == '237,106,41') {
            $(this).addClass('light-colour');
        }

        if(yiq >= 128) {
            $(this).removeClass('light-colour');
        }

        else {
        	$(this).addClass('light-colour');
        }
    });
};

$('header.public-header .org-title h4, .public-footer .footer-content, .public-footer .footer-content a').contrastColour();


if ( $('header.public-header .org-title > h4').hasClass('light-colour') ) {

    $('.public-header .powered-by img').attr('src', 'https://assets.race-nation.com/platform-resources/poweredby_white.png');

}

else {

    $('.public-header .powered-by img').attr('src', 'https://assets.race-nation.com/platform-resources/poweredby_black.png');

}

$('.public-accordion .content').hide();
$('.public-accordion .content.active').show();
